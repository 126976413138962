body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.conv-name {
  font-weight: 600;
}

#greeting {
  color: white;
  font-size: 38px;
  font-weight: bold;
  position: relative;
  margin: 3% auto;
}

#loading-background {
  background-color: #37525f;
  height: 100vh;
}

#loading-logo {
  display: block;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10%;
}

#not-found {
  height: 100vh;
  background-color: #F7C3B6;
}

#not-found-text {
  color: #37525f;
}
